// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

@placeholder-opacity: 0.1;
@padding: 1.5rem;
@small-padding: 1rem;
@logo-size: 8rem;

.placeholder-pill(@width: 100%, @height: 1.3rem) {
    background-color: var(--primary-foreground-color);
    border-radius: var(--border-radius);
    opacity: @placeholder-opacity;
    width: @width;
    height: @height;
}

.placeholder-logo(@size: @logo-size) {
    width: @size;
    height: @size;
    border-radius: 50%;
    background-color: var(--primary-foreground-color);
    opacity: @placeholder-opacity;
}

.addon-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: @padding;
    border-radius: var(--border-radius);
    background-color: var(--overlay-color);
    cursor: inherit;

    .content {
        display: flex;
        flex: 1;

        .logo-container {
            flex: none;
            .placeholder-logo(@logo-size);
        }

        .info-container {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            padding: @small-padding;
            flex: 1;

            .placeholder-pill:nth-child(1) {
                .placeholder-pill(40%);
            }

            .placeholder-pill:nth-child(2) {
                .placeholder-pill(60%);
            }

            .placeholder-pill:nth-child(3) {
                .placeholder-pill(80%);
            }

            .placeholder-pill:nth-child(4) {
                display: none;
            }
        }
    }

    .buttons-container {
        flex: none;
        display: flex;
        flex-direction: column;
        gap: @small-padding;
        width: 30%;
        max-width: 18rem;

        .action-buttons-container {
            display: flex;
            flex-direction: row;
            gap: @small-padding;

            .placeholder-pill:nth-child(1), .placeholder-pill:nth-child(2) {
                .placeholder-pill(50%, 3.5rem);
            }
        }

        .placeholder-pill:last-child {
            .placeholder-pill(100%, 3.5rem);
        }
    }
}

@media screen and (max-width: @minimum) {
    .addon-container {
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        gap: 1rem;

        .content {
            flex-direction: row;
            width: 100%;

            .logo-container {
                margin: 0 auto;
            }

            .info-container {
                padding: 1rem;
                width: 100%;

                .placeholder-pill:nth-child(1) {
                    .placeholder-pill(60%);
                }

                .placeholder-pill:nth-child(2) {
                    .placeholder-pill(40%);
                }

                .placeholder-pill:nth-child(3) {
                    .placeholder-pill(80%, 0.8rem);
                }

                .placeholder-pill:nth-child(4) {
                    display: block;
                    .placeholder-pill(20%, 0.8rem);
                }
            }
        }

        .buttons-container {
            flex-direction: column;
            align-items: stretch;
            width: 100%;
            gap: 0.5rem;
            max-width: none;

            .action-buttons-container {
                display: none;
            }
        }
    }
}

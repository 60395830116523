// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

.item {
    display: flex;
    padding: 0.7rem 1.5rem;
    border-radius: 2.5rem;
    transition: 0.3s all ease-in-out;
    background-color: var(--overlay-color);
    border: 2px solid transparent;
    justify-content: space-between;
    position: relative;

    .content {
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
        max-width: 60%;

        .selectable {
            overflow: visible;
        }

        .label {
            color: var(--primary-foreground-color);
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .actions {
        display: flex;
        gap: 1rem;
        padding-right: 4rem;

        .status {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;

            .icon {
                width: 0.75rem;
                height: 0.75rem;
                border-radius: 1rem;

                &.ready {
                    background-color: var(--secondary-accent-color);
                }

                &.error {
                    background-color: var(--color-trakt);
                }
            }

            .label {
                font-size: 1rem;
                color: var(--primary-foreground-color);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .delete {
            position: absolute;
            display: flex;
            right: 1.5rem;
            top: 50%; 
            gap: 0.5rem;
            padding: 0.5rem 0.25rem;
            align-items: center;
            justify-content: center;
            background-color: transparent;
            transition: 0.3s all ease-in-out;
            border-radius: var(--border-radius);
            transform: translateY(-50%);
            width: 3rem;

            .icon {
                width: 1.5rem;
                height: 1.5rem;
                opacity: 0;
                transition: 0.3s all ease-in-out;
                color: var(--primary-foreground-color);
            }

            &:hover, &:focus {
                background-color: var(--overlay-color);

                .icon {
                    color: var(--color-trakt);
                    opacity: 1 !important;
                }
            }
        }
    }

    &:hover {
        border: 2px solid var(--overlay-color);
    
        .actions {
            .delete {
                .icon {
                    opacity: 0.6;
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .item {
        padding: 0.7rem 1rem;

        .actions {
            .delete {
                right: 1rem;

                .icon {
                    opacity: 0.6;
                }
            }
        }
    }
}
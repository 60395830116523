.audio-menu {
    display: flex;
    flex-direction: row;

    .container {
        flex: none;
        align-self: stretch;
        display: flex;
        flex-direction: column;
        width: 16rem;

        .header {
            flex: none;
            align-self: stretch;
            padding: 1.5rem 2rem;
            font-weight: 700;
            color: var(--primary-foreground-color);
        }

        .list {
            flex: 1;
            align-self: stretch;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            overflow-y: auto;
            padding: 0 1rem;
            padding-bottom: 1rem;

            .option {
                flex: none;
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;
                height: 4rem;
                padding: 0 1.5rem;
                border-radius: var(--border-radius);

                &:global(.selected), &:hover {
                    background-color: var(--overlay-color);
                }

                .info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    gap: 0.25rem;

                    .lang, .label {
                        flex: auto;
                        text-wrap: nowrap;
                        text-overflow: ellipsis;
                    }

                    .lang {
                        font-size: 1.1rem;
                        line-height: 1.5rem;
                        color: var(--primary-foreground-color);
                    }

                    .label {
                        font-size: 0.9rem;
                        color: var(--color-placeholder-text);
                    }
                }

                .icon {
                    flex: none;
                    width: 0.5rem;
                    height: 0.5rem;
                    border-radius: 100%;
                    background-color: var(--secondary-accent-color);
                }
            }
        }
    }
}
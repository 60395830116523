// Copyright (C) 2017-2024 Smart code 203358507

.wrapper {
    display: flex;
    flex-direction: column;
    max-width: 35rem;
    margin-bottom: 2rem;

    .header {
        display: flex;
        justify-content: space-between;
        padding: 0 3rem;
        align-items: center;
        
        .label {
            font-size: 1rem;
            color: var(--primary-foreground-color);
            font-weight: 400;
            opacity: 0.6;

            &:last-of-type {
                padding-right: 3rem;
            }
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1.5rem 0;
    }
    
    .item {
        display: flex;
        padding: 1rem 1.5rem;
        border-radius: var(--border-radius);
        transition: 0.3s all ease-in-out;
        background-color: transparent;
        border: 2px solid transparent;
        justify-content: space-between;
        position: relative;
    }

    .footer {
        display: flex;
        justify-content: space-between;

        .add-url {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
            padding: 0.5rem 1.5rem;
            background-color: var(--secondary-accent-color);
            transition: 0.3s all ease-in-out;
            border-radius: 1.5rem;
            color: var(--primary-foreground-color);
            border: 2px solid transparent;

            .icon {
                width: 1rem;
                height: 1rem;
                color: var(--primary-foreground-color);
            }

            &:hover {
                background-color: transparent;
                border: 2px solid var(--primary-foreground-color);
            }
        }

        .reload {
            display: flex;
            gap: 0.5rem;
            align-items: center;
            justify-content: center;
            padding: 0.5rem 1.5rem;
            background-color: var(--overlay-color);
            border-radius: 1.5rem;
            transition: 0.3s all ease-in-out;
            color: var(--primary-foreground-color);
            border: 2px solid transparent;

            .icon {
                width: 1rem;
                height: 1rem;
                color: var(--primary-foreground-color);
            }

            &:hover {
                background-color: transparent;
                border: 2px solid var(--primary-foreground-color);
            }
        }
    }
}
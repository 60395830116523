// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

.placeholder {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
    overflow-y: auto;

    .title {
        font-size: 1.75rem;
        font-weight: 400;
        text-align: center;
        color: var(--primary-foreground-color);
        margin-bottom: 1rem;
        opacity: 0.5;
    }

    .image-container {
        padding: 1.5rem 0;

        .image {
            height: 100%;
            max-height: 14rem;
            object-fit: contain;
        }
    }

    .overview {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4rem;
        margin-bottom: 1rem;

        .point {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1.5rem;
            width: 18rem;

            .icon {
                flex: none;
                height: 3.25rem;
                width: 3.25rem;
                color: var(--primary-foreground-color);
                opacity: 0.3;
            }

            .text {
                flex: auto;
                font-size: 1.1rem;
                font-size: 500;
                color: var(--primary-foreground-color);
                opacity: 0.9;
            }
        }
    }

    .button-container {
        margin: 1rem 0;
    
        .button {
            display: flex;
            justify-content: center;
            height: 4rem;
            line-height: 4rem;
            padding: 0 5rem;
            font-size: 1.1rem;
            color: var(--primary-foreground-color);
            text-align: center;
            border-radius: 3.5rem;
            background-color: var(--overlay-color);

            &:hover {
                outline: var(--focus-outline-size) solid var(--primary-foreground-color);
                background-color: transparent;
            }
        }
    }
}

@media only screen and (max-width: @xsmall) {
    .placeholder {
        padding: 1rem 2rem;

        .title {
            margin-bottom: 0;
        }

        .image-container {
            padding: 1rem;

            .image {
                max-height: 10rem;
            }
        }

        .button-container {
            margin: 1rem 0 0;
        }
    }
}

@media only screen and (max-width: @minimum) {
    .placeholder {
        padding: 1rem 2rem;

        .overview {
            flex-direction: column;
            gap: 1rem;

            .point {
                .text {
                    font-size: 1rem;
                }
            }
        }

        .button-container {
            .button {
                width: 100%;
            }
        }
    }
}
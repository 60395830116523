// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.radio-button {
    display: flex;
    align-items: center;
    overflow: visible;

    .radio-container {
        position: relative;
        width: 1.75rem;
        height: 1.75rem;
        border: 3px solid var(--color-placeholder);
        border-radius: 1rem;
        background-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        outline: none;
        user-select: none;
        outline-width: var(--focus-outline-size);
        outline-color: @color-surface-light5;
        outline-offset: calc(-1 * var(--focus-outline-size));

        input[type='radio'] {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
            cursor: pointer;
        }

        .inner-circle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1.25rem;
            height: 1.25rem;
            border-radius: 0.675rem;
            border: 2px solid var(--secondary-background-color);
            transition: opacity 0.2s ease-in-out;
            background-color: transparent;
            opacity: 0;
        }

        &.disabled {
            cursor: not-allowed;
        }

        &.error {
            border-color: var(--color-trakt);
        }

        &.selected {
            .inner-circle {
                background-color: var(--primary-accent-color);
                opacity: 1;
            }
        }

        &:focus {
            outline-style: solid;
        }
    }
}

// Copyright (C) 2017-2025 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';

.checkbox {
    display: flex;
    align-items: center;
    overflow: visible;

    .label {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0.5rem 0;
        cursor: pointer;
        
        span {
            font-size: 0.9rem;
            color: var(--primary-foreground-color);
            opacity: 0.6;
        }

        .link {
            font-size: 0.9rem;
            color: var(--primary-accent-color);

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .checkbox-container {
        position: relative;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.3rem;
        background-color: var(--overlay-color);
        padding: 0.1rem;
        display: flex;
        flex: none;
        margin: 0 1rem 0 0.3rem;
        align-items: center;
        justify-content: center;
        transition: background-color 0.2s ease-in-out;
        cursor: pointer;
        outline: none;
        user-select: none;
        outline-width: var(--focus-outline-size);
        outline-color: @color-surface-light5;
        outline-offset: 2px;

        input[type='checkbox'] {
            opacity: 0;
            width: 0;
            height: 0;
            position: absolute;
            cursor: pointer;
        }

        .checkbox-icon {
            width: 100%;   
            height: 100%;
            color: var(--primary-foreground-color);
        }

        &.disabled {
            cursor: not-allowed;
        }

        &.error {
            border-color: var(--color-trakt);
        }

        &.checked {
            background-color: var(--primary-accent-color);
        }

        &:hover, &:focus {
            outline-style: solid;
        }
    }
}

// Copyright (C) 2017-2023 Smart code 203358507

@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/components/ModalDialog/styles.less') {
    modal-dialog-content: modal-dialog-content;
    modal-dialog-container: modal-dialog-container;
}

.event-modal {
    backdrop-filter: blur(10px);

    .modal-dialog-container {
        overflow: visible;
        max-width: 45rem;

        .modal-dialog-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: visible;
            position: relative;

            .body-container {
                overflow-y: visible;
            }

            .image {
                position: absolute;
                top: -10rem;
                left: 50%;
                transform: translateX(-50%);
                object-fit: cover;
                width: 30rem;
                height: 30rem;
            }

            .info-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: 2.5rem;
                padding: 10rem 4rem 0;

                .title-container {
                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    .title {
                        color: var(--primary-foreground-color);
                        font-size: 1.325rem;
                        text-align: center;
                        padding: 0 6rem;
                    }

                    .label {
                        color: var(--primary-foreground-color);
                        font-size: 1rem;
                        text-align: center;
                        opacity: 0.5;
                    }
                }

                .addon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    gap: 0.5rem;

                    .icon {
                        height: 2rem;
                        width: 2rem;
                        color: var(--primary-accent-color);
                    }

                    .name {
                        color: var(--primary-foreground-color);
                    }
                }

                .action-button {
                    background-color: var(--primary-foreground-color);
                    border: 2px solid var(--primary-foreground-color);
                    padding: 0.8rem 2rem;
                    border-radius: 2rem;

                    .button-label {
                        color: var(--primary-accent-color);
                        font-size: 1rem;
                        font-weight: 700;
                    }

                    &:hover {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}

@media (orientation: landscape) and (max-height: @minimum) {
    .event-modal {
        .modal-dialog-container {
            .modal-dialog-content {
                overflow-y: auto;

                .body-container {
                    overflow-y: auto;
                }

                .image {
                    display: none;
                }

                .info-container {
                    padding: 1rem 4rem 0;
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .event-modal {
        .modal-dialog-container {
            .modal-dialog-content {
                .info-container {
                    .title-container {
                        .title {
                            padding: 0rem;
                            font-size: 1rem;
                        }

                        .label {
                            font-size: 0.875rem;
                        }
                    }
                }
            }
        }
    }
}

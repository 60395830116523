// Copyright (C) 2017-2024 Smart code 203358507

@height: 2.75rem;

.chip {
    flex: none;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: @height;
    font-size: 1rem;
    font-weight: 500;
    color: var(--primary-foreground-color);
    white-space: nowrap;
    text-transform: capitalize;
    padding: 0 1.75rem;
    border-radius: @height;
    background-color: transparent;
    user-select: none;
    overflow: hidden;
    opacity: 0.6;

    &:hover {
        background-color: var(--overlay-color);
        transition: background-color 0.1s ease-out;
        opacity: 1;
    }

    &.active {
        opacity: 1;
        background-color: var(--quaternary-accent-color);
        transition: background-color 0.1s ease-in;
    }
}
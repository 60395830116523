// Copyright (C) 2017-2024 Smart code 203358507

@import (reference) '~@stremio/stremio-colors/less/stremio-colors.less';
@import (reference) '~stremio/common/screen-sizes.less';

:import('~stremio/components/MetaRow/styles.less') {
    meta-item: meta-item;
}

:import('~stremio/components/MetaRow/MetaRowPlaceholder/styles.less') {
    meta-item-placeholder: meta-item;
}

.board-container {
    width: 100%;
    height: calc(100% - var(--safe-area-inset-bottom));
    display: flex;
    flex-direction: column;

    .board-content-container {
        flex: 1;
        align-self: stretch;
        background: transparent;

        .board-content {
            height: 100%;
            width: 100%;
            padding: 0 1rem;
            overflow-y: auto;

            .board-row {
                margin-top: 1rem;
                margin-bottom: 2rem;
            }
        }
    }

    .board-warning-container {
        position: absolute;
        bottom: calc(var(--safe-area-inset-bottom) + 0.5rem);
        left: calc(var(--safe-area-inset-left) + 0.5rem);
        right: calc(var(--safe-area-inset-right) + 0.5rem);
    }
}

@media only screen and (max-width: @large) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+10) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+9) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @normal) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+9) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+8) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @medium) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+8) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+7) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @small) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+7) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+6) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @xsmall) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+6) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+5) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @xxsmall) {
    .board-container {
        .board-content-container {
            .board-content {
                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+5) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+4) {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: @minimum) {
    .board-container {
        position: relative;
        z-index: 0;

        .board-content-container {
            .board-content {
                .board-row {
                    margin-bottom: 1.5rem;
                }

                .board-row-poster, .board-row-square, .continue-watching-row {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+4) {
                            display: none;
                        }
                    }
                }

                .board-row-landscape {
                    .meta-item, .meta-item-placeholder {
                        &:nth-child(n+3) {
                            display: none;
                        }
                    }
                }
            }
        }
        
        .board-warning-container {
            bottom: calc(var(--vertical-nav-bar-size) + 0.5rem);
            height: 7rem;
        }
    }
}

@media @phone-landscape {
    .board-container {
        .board-warning-container {
            left: calc(var(--safe-area-inset-left) + var(--vertical-nav-bar-size) + 0.5rem);
        }
    }
}
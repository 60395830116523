// Copyright (C) 2017-2024 Smart code 203358507

.item {
    flex: none;
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--overlay-color);
    border-radius: var(--border-radius);
    border: 0.15rem solid transparent;
    transition: border-color 0.1s ease-out;

    .heading {
        flex: none;
        position: relative;
        display: flex;
        align-items: center;
        height: 3.5rem;
        font-size: 1rem;
        font-weight: 500;
        color: var(--primary-foreground-color);
        padding: 0 1rem;
    }

    .body {
        flex: auto;
        display: flex;
        flex-direction: column;

        .video {
            flex: none;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 1rem;
            height: 3rem;
            padding: 0 1rem;
            font-size: 1rem;
            font-weight: 500;
            color: var(--primary-foreground-color);

            &:last-child {
                border-radius: 0 0 var(--border-radius) var(--border-radius);
            }

            .name {
                flex: auto;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .info {
                flex: none;
                display: block;
            }

            .icon {
                flex: none;
                display: none;
                width: 2rem;
                height: 2rem;
                padding: 0.5rem;
                border-radius: 50%;
                color: var(--primary-foreground-color);
                background-color: var(--secondary-accent-color);
            }

            &:hover {
                background-color: var(--overlay-color);

                .info {
                    display: none;
                }

                .icon {
                    display: block;
                }
            }
        }
    }

    &.today {
        .heading {
            background-color: var(--primary-accent-color);
        }
    }

    &.active {
        border-color: var(--primary-foreground-color);
    }

    &:not(.active):hover {
        border-color: var(--overlay-color);
    }
}
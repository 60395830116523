.updater-banner {
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0 1rem;
    font-size: 1rem;
    font-weight: bold;
    color: var(--primary-foreground-color);
    background-color: var(--primary-accent-color);

    .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 2.5rem;
        padding: 0 1rem;
        border-radius: var(--border-radius);
        color: var(--primary-background-color);
        background-color: var(--primary-foreground-color);
        transition: all 0.1s ease-out;

        &:hover {
            color: var(--primary-foreground-color);
            background-color: transparent;
            box-shadow: inset 0 0 0 0.15rem var(--primary-foreground-color);
        }
    }

    .close {
        position: absolute;
        right: 0;
        height: 4rem;
        width: 4rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .icon {
            height: 2rem;
        }
    }
}